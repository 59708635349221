<script lang="ts">
    let classNames = ''
    export { classNames as class }
</script>

<div class="container {classNames}" {...$$restProps}>
    <slot />
</div>

<style lang="postcss">
    .container {
        @media screen(sm) {
            max-width: theme('container.maxWidths.sm');
        }

        @media screen(md) {
            max-width: theme('container.maxWidths.md');
        }

        @media screen(lg) {
            max-width: theme('container.maxWidths.lg');
        }

        @media screen(xl) {
            max-width: theme('container.maxWidths.xl');
        }
    }
</style>
