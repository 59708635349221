import local from './envs/local'
import production from './envs/production'
import staging from './envs/staging'

const sites = {
    local: {
        ...local,
    },
    production: {
        ...production,
    },
    staging: {
        ...staging,
    },
}

enum Site {
    admin = 'admin',
    status = 'status',
    web = 'web',
}

function getSiteLink(site: Site) {
    const ENV = import.meta.env.VITE_LINKS_ENV || process.env.NODE_ENV
    return sites[ENV as keyof typeof sites][site]
}

export { getSiteLink, Site }
