<script lang="ts">
    import { concatClasses } from '../utils'

    export let level: '1' | '2' | '3' | '4' | '5' | '6' = '1'
    export let size = level
    export let fontFamily: 'display' | 'sans' = 'display'
    export let decoration = false
    export let useDiv = false

    let classNames = ''
    export { classNames as class }

    const classes = concatClasses([
        'heading',
        `heading-${fontFamily}`,
        size ? `heading-${size}` : '',
        decoration ? `heading-decoration` : '',
        classNames,
    ])
</script>

<svelte:element
    this={useDiv ? 'div' : `h${level}`}
    class={classes}
    {...$$restProps}
>
    <slot />
</svelte:element>

<style global lang="postcss">
    .heading {
        span::before {
            content: '';
            display: block;
        }
    }

    .heading.heading-decoration {
        @media screen(sm) {
            &::before,
            &::after {
                color: var(--primary-300, theme('colors.gray-300'));
            }

            &::before {
                content: '/';
                margin-right: theme('spacing.1');
                margin-left: -1.5rem;
            }

            &::after {
                content: '.';
            }
        }
    }

    .heading.heading-display {
        font-family: theme('fontFamily.display');
    }

    .heading.heading-sans {
        font-weight: theme('fontWeight.bold');
        font-family: theme('fontFamily.sans');
    }

    .heading.heading-1 {
        font-size: clamp(1.9rem, 1.426rem + 2.37vw, 3rem);
    }

    .heading.heading-2 {
        font-size: clamp(1.75rem, 1.5rem + 1vw, 2.5rem);
    }

    .heading.heading-3 {
        font-size: theme('fontSize.h4');

        @media screen(md) {
            font-size: theme('fontSize.h3');
        }
    }

    .heading.heading-4 {
        font-size: theme('fontSize.h5');

        @media screen(md) {
            font-size: theme('fontSize.h4');
        }
    }

    .heading.heading-5 {
        font-size: theme('fontSize.h6');

        @media screen(md) {
            font-size: theme('fontSize.h5');
        }
    }

    .heading.heading-6 {
        font-size: theme('fontSize.h6');
    }

    .heading.heading-blogpost {
        font-size: clamp(1.5rem, 0.955rem + 2.73vw, 3rem);
    }
</style>
