<script lang="ts">
    import type { Element } from '../types'
    import { concatClasses } from '../utils'

    let classNames = ''
    export { classNames as class }
    export let alignItems: 'normal' | 'stretch' | 'center' | 'start' | 'end' =
        'normal'
    export let direction: 'row' | 'col' = 'row'
    export let element: Element = 'div'
    export let justifyContent:
        | 'around'
        | 'between'
        | 'end'
        | 'evenly'
        | 'start'
        | 'center'
        | 'stretch' = 'start'
    export let nowrap = false

    $: classes = concatClasses([
        'flex-layout',
        `flex-${direction}`,
        `justify-${justifyContent}`,
        `items-${alignItems}`,
        nowrap ? 'flex-nowrap' : '',
        classNames,
    ])
</script>

<svelte:element this={element} class={classes} {...$$restProps}>
    <slot />
</svelte:element>

<style global lang="postcss">
    .flex-layout {
        display: flex;
        flex-wrap: wrap;
        gap: var(--gap-y) var(--gap-x);
    }

    .flex-layout.flex-row {
        flex-direction: row;
    }

    .flex-layout.flex-col {
        flex-direction: column;
    }

    .flex-layout.justify-stretch {
        justify-content: stretch;
    }

    .flex-layout.justify-between {
        justify-content: space-between;
    }

    .flex-layout.justify-around {
        justify-content: space-around;
    }

    .flex-layout.justify-evenly {
        justify-content: space-evenly;
    }

    .flex-layout.justify-center {
        justify-content: center;
    }

    .flex-layout.justify-start {
        justify-content: start;
    }

    .flex-layout.justify-end {
        justify-content: end;
    }

    .flex-layout.items-normal {
        align-items: normal;
    }

    .flex-layout.items-stretch {
        align-items: stretch;
    }

    .flex-layout.items-center {
        align-items: center;
    }

    .flex-layout.items-start {
        align-items: start;
    }

    .flex-layout.items-end {
        align-items: end;
    }
</style>
